import React from 'react';
import {Layout} from "../containers/Layout";
import {Link, PageProps} from "gatsby";
import styled from 'styled-components';
import {getDebug} from "../utils";

const debug = getDebug(__filename);

export type BlockPageProps = {
    id: string | number;
} & PageProps;
export function BlacklistPage({id, ...pageProps}: BlockPageProps) {
    return <StyledLayout {...pageProps}>
        <div id={`blacklist_${id}`}/>
        <div>
            <ToIndex><Link to={'/'}>&lt; index</Link></ToIndex>
            <BlackList>Blacklist</BlackList>
            <Path>{pageProps.location.href}</Path>
        </div>
    </StyledLayout>
}

const StyledLayout = styled(Layout)`
  .main {
    justify-content: center;  
  }
`;

const ToIndex = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
  a {
    color: #333333;
    text-decoration: none;
    
    &:active, &:hover {
      color: #595959;
    }  
  }
`;

const BlackList = styled.div`
  font-size: 4rem;
  margin-left: -0.4rem;
`;

const Path = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
`;
